import { Component, Vue } from 'vue-property-decorator'
import Sidebar from '../../components/layout/Sidebar.vue'
import Navbar from '../../components/layout/Navbar.vue'
import MeetingWidget from '../../components/dashboard/prospect/meeting/MeetingWidget.vue'
import FollowUpWidget from '../../components/dashboard/prospect/followup/FollowUpWidget.vue'
import EmployeeStatRadarChart from './chart/EmployeeStatRadarChart.vue'
import EmployeeStatBarChart from './chart/EmployeeStatBarChart.vue'
import TeamStatBarChart from './chart/TeamStatBarChart.vue'
import network from '@/services/Network'
import { namespace } from 'vuex-class'
import axios from 'axios'

const Auth = namespace('AuthModule')

declare namespace EmployeeStats {

    export interface Data {
        number_of_prospect_created: number;
        number_of_meetings: number;
        number_of_calls: number;
        number_of_prospect_followup: number;
    }

    export interface RootObject {
        error?: any;
        timestamp: string;
        status: string;
        data: Data;
    }

}

@Component({
  components: {
    Sidebar,
    Navbar,
    EmployeeStatRadarChart,
    MeetingWidget,
    FollowUpWidget,
    TeamStatBarChart,
    EmployeeStatBarChart
  }
})
export default class DashboardOverview extends Vue {
    @Auth.Getter
    private userDetail!: any;

    private employeeStats = {} as EmployeeStats.Data;

    private isLoading = true;
    private teamOverview = false;
    private teamStatsInterval = 'today';
    private employeeStatsInterval = 'today';
    private teamStats = {};
    private isStatLoading = false;
    private isEmployeeStatLoading = false;
    private accumulator = {
      prospect_call: 0,
      meeting_inhouse: 0,
      meeting_outhouse: 0,
      closings: 0
    };

    async created () {
      await this.getEmployeeStats()
      const isManager = this.userDetail.user.authorities.filter((authorityObj: any) => authorityObj.authority === 'MANAGER')
      this.teamOverview = isManager.length !== 0
      if (this.teamOverview) {
        await this.getTeamStats()
      }
      this.isLoading = false
    }

    private async getEmployeeStats () {
      this.isEmployeeStatLoading = true
      const { data: response } = await network().get(`/employee/stats?interval=${this.employeeStatsInterval}`)
      this.employeeStats = response.data
      this.isEmployeeStatLoading = false
    }

    private async getTeamStats () {
      this.isStatLoading = true
      const teamStatBuffer: any = {}
      this.accumulator = {
        prospect_call: 0,
        meeting_inhouse: 0,
        meeting_outhouse: 0,
        closings: 0
      }

      const response = await axios.all([
        network().get(`/prospect-call/count?interval=${this.teamStatsInterval}`),
        network().get(`/meeting/count?interval=${this.teamStatsInterval}`),
        network().get(`/meeting/count?interval=${this.teamStatsInterval}&type=outhouse`),
        network().get(`/prospect/count?interval=${this.teamStatsInterval}`)
      ])
      const prospectCallResponse = response[0].data
      const meetingResponseInhouse = response[1].data
      const meetingResponseOuthouse = response[2].data
      const prospectClosingMarginSum = response[3].data

      prospectCallResponse.data.forEach((element: any) => {
        teamStatBuffer[element.group_name] = {
          ...teamStatBuffer[element.group_name], prospect_call: element['COUNT(DISTINCT(prospect_call_a.prospect_call_id))']
        }
        this.accumulator.prospect_call += element['COUNT(DISTINCT(prospect_call_a.prospect_call_id))']
      })
      meetingResponseInhouse.data.forEach((element: any) => {
        teamStatBuffer[element.group_name] = { ...teamStatBuffer[element.group_name], meeting_inhouse: element['COUNT(meeting_a.meeting_id)'] }
        this.accumulator.meeting_inhouse += element['COUNT(meeting_a.meeting_id)']
      })
      meetingResponseOuthouse.data.forEach((element: any) => {
        teamStatBuffer[element.group_name] = { ...teamStatBuffer[element.group_name], meeting_outhouse: element['COUNT(meeting_a.meeting_id)'] }
        this.accumulator.meeting_outhouse += element['COUNT(meeting_a.meeting_id)']
      })
      prospectClosingMarginSum.data.forEach((element: any) => {
        teamStatBuffer[element.group_name] = { ...teamStatBuffer[element.group_name], closing_margin_sum: element['COALESCE(SUM(prospect_a.final_closing_margin), 0)'] }
      })
      this.teamStats = teamStatBuffer
      for (const [k, v] of Object.entries(this.teamStats)) {
        // @ts-ignore
        if (v.closing_margin_sum) {
          // @ts-ignore
          this.accumulator.closings += v.closing_margin_sum
        }
      }
      this.isStatLoading = false
    }

    async handleTabChange (value: string) {
      this.teamStatsInterval = value.toLowerCase()
      await this.getTeamStats()
    }

    async handleTabEmployeeStatChange (value: string) {
      this.employeeStatsInterval = value.toLowerCase()
      await this.getEmployeeStats()
    }

    currencyFormatter (value: number | number | string) {
      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PKR'
      })
      return currencyFormatter.format(value as number)
    }
}
