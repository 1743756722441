
import { Radar } from 'vue-chartjs'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Util from '../../../mixins/Util'
@Component
export default class EmployeeStatRadarChart extends Mixins(Radar) {
  @Prop({ required: true }) employeeStat: any;
  mounted () {
    this.renderChart(
      {
        labels: Object.keys(this.employeeStat).map((elemnt) =>
          Util.titleSize(elemnt.split('_').join(' '))
        ),
        datasets: [
          {
            label: 'General Stats',
            backgroundColor: 'rgb(35, 182, 235, 0.1)',
            borderColor: 'rgb(35, 182, 235)',
            data: (Object.values(this.employeeStat) as unknown) as number[]
          }
        ]
      },
      {
        responsive: true
      }
    )
  }
}
