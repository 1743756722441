import network from '@/services/Network'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'FollowUpWidget' })
export default class FollowUpWidget extends Vue {
    private followUps: Array<any> = [];
    private query = 'today';
    private isLoading = false;
    async created () {
      await this.getFollowUps()
    }

    async getFollowUps () {
      this.isLoading = true
      const response = await network().get(`/followup/schedule?query=${this.query}`)
      this.followUps = response.data.data.content
      this.isLoading = false
    }

    async handleTabChange (value: string) {
      this.query = value
      await this.getFollowUps()
    }
}
