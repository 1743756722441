import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const Auth = namespace('AuthModule')

@Component({
  name: 'Navbar'
})
export default class Sidebar extends Vue {
    @Auth.Getter
    private userDetail!: object;

    @Auth.Getter
    private userType!: Array<string>;

    @Auth.Action
    private signOut!: () => void;

    private isPrivileged = false;

    @Prop({ required: false, type: String, default: 'overview' }) readonly active!: Array<string>;

    beforeMount (): void {
      //* Check if the user has privilege to see the admin level or manager level menu
      this.isPrivileged = this.userType.includes('ADMIN' || 'MANAGER')
    }
}
