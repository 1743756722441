import { Component, Vue } from 'vue-property-decorator'

interface ObjectDifferenceType {
    isDifferent: boolean;
    deltaObject: any
}

@Component
export default class Util extends Vue {
  /**
     * Return a title case of given word
     *
     * @param word The word that is to be converted to title case word
     */
  public static titleSize (word: string): string {
    let upper = true
    let newStr = ''
    for (let i = 0, l = word.length; i < l; i++) {
      //* Note that you can also check for all kinds of spaces with
      // str[i].match(/\s/)
      if (word[i] == ' ') {
        upper = true
        newStr += word[i]
        continue
      }
      newStr += upper ? word[i].toUpperCase() : word[i].toLowerCase()
      upper = false
    }
    return newStr
  }

  /**
     * Return an array of path without the slashes('/')
     * @param route The URL
     */
  public routeToPathArray (route: string): Array<string> {
    return route.split('/')
      .filter((path) => path)
      .map(path => path.split('-').join(' '))
      .map((path) => Util.titleSize(path))
  }

  public enumToStringValues (enumObjet: object): Array<string> {
    return Object.keys(enumObjet).filter(category => !parseInt(category))
  }

  public getObjectDifference (baseObject: any, changedObject: any): ObjectDifferenceType {
    const diffBuffer = new Object()
    const keys = Object.keys(baseObject)
    let shouldUpdate = false

    keys.forEach(key => {
      if (baseObject[key] !== changedObject[key]) {
        shouldUpdate = true
        Object.assign(diffBuffer, { [key]: changedObject[key] })
      }
    })

    if (!shouldUpdate) {
      return { isDifferent: false, deltaObject: null } as ObjectDifferenceType
    }
    return { isDifferent: true, deltaObject: diffBuffer } as ObjectDifferenceType
  }
}
