import { Component, Vue, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const Auth = namespace('AuthModule')

@Component({
  name: 'Navbar'
})
export default class Navbar extends Vue {
    @Auth.Getter
    private userDetail!: any;

    @Auth.Action
    private signOut!: () => void;

    private activeNavLink: Array<string> = [];
    private reports = false;

    created () {
      this.activeNavLink = this.$route.path.split('/')
      const isManager = this.userDetail.user.authorities.filter((authorityObj: any) => authorityObj.authority === 'MANAGER')
      this.reports = isManager.length !== 0
    }
}
