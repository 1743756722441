
import { Bar } from "vue-chartjs";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Util from "../../../mixins/Util";

@Component
export default class TeamStatBarChart extends Mixins(Bar) {
  @Prop({ required: true }) teamStats: any;
  mounted() {
    this.renderChart(
      {
        labels: Object.keys(this.teamStats).map((elemnt) =>
          Util.titleSize(elemnt.split("_").join(" "))
        ),
        datasets: [
          {
            label: "Prospect Calls",
            backgroundColor: "rgb(35, 182, 235)",
            borderColor: "rgb(35, 182, 235)",
            data: Object.values(this.teamStats).map(
              (stat: any) => stat.prospect_call
            ) as unknown as number[],
          },
          {
            label: "In House Meetings",
            backgroundColor: "rgb(93,138,168)",
            borderColor: "rgb(93,138,168)",
            data: Object.values(this.teamStats).map(
              (stat: any) => stat.meeting_inhouse
            ) as unknown as number[],
          },
          {
            label: "Out House Meetings",
            backgroundColor: "rgb(114,160,193)",
            borderColor: "rgb(114,160,193)",
            data: Object.values(this.teamStats).map(
              (stat: any) => stat.meeting_outhouse
            ) as unknown as number[],
          },
        ],
      },
      {
        responsive: true,
      }
    );
  }
}
