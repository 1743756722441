import network from '@/services/Network'
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'MeetingWidget' })
export default class MeetingWidget extends Vue {
    private meetings: Array<any> = [];
    private query = 'today';
    private isLoading = false;
    async created () {
      await this.getMeetings()
    }

    async getMeetings () {
      this.isLoading = true
      const response = await network().get(`/meeting/schedule?query=${this.query}`)
      this.meetings = response.data.data.content
      this.isLoading = false
    }

    async handleTabChange (value: string) {
      this.query = value
      await this.getMeetings()
    }
}
